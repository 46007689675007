<template>
  <v-container>
    <v-row class="center-content">
      <v-col cols="6" class="offset-3">
        <v-card class="mb-7  py-5">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Change Password</span>
          </v-card-title>
          <v-card-text class="d-flex justify-center" style="border">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="10" class="offset-1">
                  <v-text-field
                    v-model="changePasswordform.currentPassword"
                    :type="showcurrentPassword ? 'text' : 'password'"
                    :rules="[(v) => !!v || 'Current Password is required']"
                    label="Current Password"
                    required
                    prepend-icon="mdi-lock"
                    :append-icon="showcurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showcurrentPassword = !showcurrentPassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="offset-1">
                  <v-text-field
                    v-model="changePasswordform.newPassword"
                    :type="showNewPassword ? 'text' : 'password'"
                    :rules="[(v) => !!v || 'New Password is required',v => (v && v.length >= 8) || 'new Password must be greater than 8 characters']"
                    label="New Password"
                    required
                    prepend-icon="mdi-lock"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="offset-1">
                  <v-btn color="primary" class="w-100" @click="changePassword()"> Change </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from './Snackbar.vue';

export default {
  name: "ChangePassword",
  components: {
    Snackbar
  },
  data: () => ({
    changePasswordform:{
      currentPassword:'',
      newPassword:'',
    },
    showcurrentPassword:false,
    showNewPassword:false,
    valid:true
  }),
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    changePassword(){
      if(this.$refs.form.validate()){
        axios.post(this.Node_JS_HOST + "/api/v1/web/user/change-password", this.changePasswordform)
        .then((res) => {
          if(res){
            this.snackbarText = 'Password Changed Successfully';
            this.isShowSnackbar = true;
            this.$refs.form.reset();
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Password Not Match.';
          this.isShowSnackbar = true;
        });
      }
    }
  },
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}
::v-deep.container {
  height: 100%;
}
.center-content{
    display: flex;
    height: 100%;
    align-items: center;
}
</style>